import React from 'react'
import Custom404 from 'pages/404'
function index() {
    console.log('tenantPage')
    return (
        <div>
            <Custom404 />
        </div>
    )
}

export default index
